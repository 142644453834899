import React from "react";
import VideoSection from "./VideoSection";
import ExtraFeature from "./ExtraFeature";
import Slider from "./Slider";
import NewCourse from "./NewCourse";
import AboutUs from "./AboutUs";

const MainSection = () => {
  return (
    <>
      {/* <VideoSection /> */}
      <Slider />
      <ExtraFeature />
      <NewCourse />
      <AboutUs />
    </>
  );
};

export default MainSection;
