import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Footer = () => {
  const [about, setAbout] = useState(null);
  const [newCoursesData, setNewCoursesData] = useState(null);
  const [upcomingCourseData, setUpcomingCourseData] = useState(null);
  const [footerLinkData, setFooterLinkData] = useState([]);
  const [schoolDetail, setSchoolDetail] = useState(null);

  useEffect(() => {
    gettingData();
    gettingCourseData();
    gettingFooterLinkData();
    gettingSchoolDetail();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      const data = response.data;
      const abouData = data.filter((obj) => obj.content_type === 6);
      setAbout(abouData[0]);
    }
  };

  const gettingCourseData = async () => {
    try {
      const response = await axios.get("/courses.php");

      if (response?.status === 200) {
        const data = response.data;

        // Filter data based on course type
        const newCourses = data.filter((course) => course.course_type === 1);
        const upcomingCourses = data.filter(
          (course) => course.course_type === 3
        );

        // Set state variables accordingly
        setNewCoursesData(newCourses);
        setUpcomingCourseData(upcomingCourses);
      }
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  const gettingFooterLinkData = async () => {
    let response = await axios.get("/footerLink.php");

    if (response?.status === 200) {
      setFooterLinkData(response.data);
      // console.log(response.data);
    }
  };

  const gettingSchoolDetail = async () => {
    let response = await axios.get("/schoolDetail.php");

    if (response?.status === 200) {
      setSchoolDetail(response.data[0]);
    }
  };

  // const renderContent = (content) => {
  //   return content
  //     .split("\n")
  //     .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  // };

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="footerbottom">
              <div className="col-md-3 col-sm-6">
                {about && (
                  <div className="footerwidget">
                    <h4>About Campus</h4>
                    <div className="menu-course">
                      <ul className="menu">
                        <li>
                          {about && about.description
                            ? about.description
                                .split(" ")
                                .slice(0, 30)
                                .join(" ")
                            : "No description available"}
                        </li>
                        <li>
                          <Link to="/aboutCampus">Know More..</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3 col-sm-6">
                {newCoursesData == [] && (
                  <div className="footerwidget">
                    <h4>New Courses</h4>
                    <div className="menu-course">
                      <ul className="menu">
                        {newCoursesData !== null ? (
                          newCoursesData.map((obj, ind) => {
                            return (
                              <>
                                <li key={ind}>
                                  <Link to={`/aboutcourse/${obj.id}`}>
                                    {obj.title}
                                  </Link>
                                </li>
                              </>
                            );
                          })
                        ) : (
                          <li>No Data Found</li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3 col-sm-6">
                {upcomingCourseData == [] && (
                  <div className="footerwidget">
                    <h4>Upcoming Courses</h4>
                    <div className="menu-course">
                      <ul className="menu">
                        {upcomingCourseData !== null ? (
                          upcomingCourseData.map((obj, ind) => {
                            return (
                              <>
                                <li key={ind}>
                                  <Link to={`/aboutcourse/${obj.id}`}>
                                    {obj.title}
                                  </Link>
                                </li>
                              </>
                            );
                          })
                        ) : (
                          <li>No Data Found</li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footerwidget">
                  <h4>Contact</h4>
                  <p>
                    <Link to="/contact1">
                      Have a question or need assistance?
                    </Link>
                  </p>
                  <div className="contact-info">
                    <i className="fa fa-map-marker"></i>
                    {schoolDetail !== null && schoolDetail.address}
                    <br />
                    <i className="fa fa-phone"></i>
                    {schoolDetail !== null && schoolDetail.contact}
                    <br />
                    <i className="fa fa-envelope-o"></i>{" "}
                    {schoolDetail !== null && schoolDetail.email}
                  </div>
                </div>
                {/* <!-- end widget -->  */}
              </div>
            </div>
          </div>
          <hr />
          <div className="social text-center">
            {footerLinkData.length > 0 &&
              footerLinkData.map((obj, ind) => {
                return (
                  <a href={obj.links} target="_blank" key={ind}>
                    <i className={obj.icon}></i>
                  </a>
                );
              })}
          </div>

          <div className="clear"></div>
          {/* <!--CLEAR FLOATS--> */}
        </div>
        <div className="footer2">
          <div className="container">
            <div className="row">
              <div className="col-md-12 panel">
                <div className="panel-body">
                  <p className="text-center">
                    Copyright &copy; 2024.
                    <a
                      href="https://pimsoftech.com"
                      rel="develop"
                      target="_blank"
                    >
                      pimsoftech.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row of panels --> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
